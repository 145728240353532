import ChevLeft from '@public/icons/chev-left.svg';
import clsx from 'clsx';
import { motion, useAnimate, useInView } from 'framer-motion';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';

import { useHorizontalScrollPercentage } from '@/hooks/useHorizontalScrollPercentage';
import type { CardT } from '@/utils/contentfulTypes';

interface JourneyWithAlterProps {
  headline: string;
  items: Omit<CardT, 'buttonLink' | 'buttonText'>[];
}

export const JourneyWithAlter: React.FC<JourneyWithAlterProps> = ({ headline, items }) => {
  const headerRef = useRef<HTMLHeadingElement>(null);
  const [distanceFromEdge, setDistanceFromEdge] = useState<number>(0);
  const { containerRef, onContainerScroll, scrollPercentage } = useHorizontalScrollPercentage();

  const [, animate] = useAnimate();
  const isInView = useInView(containerRef, {
    amount: 'some',
  });

  useEffect(() => {
    if (isInView) {
      animate(
        containerRef.current as any,
        { opacity: 1, translateX: 0 },
        {
          duration: 0.75,
          ease: 'easeOut',
        }
      );
    }
  }, [isInView]);

  const updateDistanceFromEdge = () => {
    if (headerRef.current) {
      const headerLeft = headerRef.current.getBoundingClientRect().left;
      setDistanceFromEdge(headerLeft);
    }
  };

  useEffect(() => {
    updateDistanceFromEdge();
    window.addEventListener('resize', updateDistanceFromEdge);

    return () => {
      window.removeEventListener('resize', updateDistanceFromEdge);
    };
  }, []);

  const prevSlide = () => {
    if (!containerRef.current) return;
    containerRef.current.scrollLeft -= 108;
  };

  const nextSlide = () => {
    if (!containerRef.current) return;
    containerRef.current.scrollLeft += 108;
  };

  return (
    <section className="pt-16 bg-[#F6F6F1] overflow-hidden">
      <div className="px-4 md:px-20">
        <h2 ref={headerRef} className="width-1440 modernGothicTrial-light text-2xl md:text-[32px]">
          {headline}
        </h2>
      </div>

      <div className="relative">
        <div className="absolute top-[50%] translate-y-[-50%] left-0 w-full flex items-center justify-between gap-2 px-4">
          <button onClick={prevSlide}>
            <Image src={ChevLeft} alt="<" width={30} height={30} className={'brightness-[10%]'} />
          </button>
          <button onClick={nextSlide}>
            <Image
              src={ChevLeft}
              alt=">"
              width={30}
              height={30}
              className={clsx('rotate-180', 'brightness-[10%]')}
            />
          </button>
        </div>

        <motion.div
          ref={containerRef}
          onScroll={onContainerScroll}
          initial={{ opacity: 0, translateX: 160 }}
          className="flex gap-4 mt-5 overflow-auto no-scrollbar md:mt-8"
        >
          {items.map((item, index) => (
            <div
              key={item.headline}
              className="flex flex-col gap-4 max-w-[308px] [&_*]:modernGothicTrial-light first:ml-6 last:mr-6"
              style={{
                marginLeft: index === 0 ? distanceFromEdge : undefined,
                marginRight: index === items.length - 1 ? distanceFromEdge : undefined,
              }}
            >
              <div className="h-[256px] w-[256px] aspect-square md:h-[308px] md:w-[308px]">
                <Image
                  src={item.image.url}
                  alt={item.headline}
                  width={400}
                  height={400}
                  className="object-cover "
                />
              </div>
              <h3 className="text-lg md:text-xl">{item.headline}</h3>
              <p className="text-alter-black-80 text-sm md:text-base">{item.text}</p>
            </div>
          ))}
        </motion.div>
      </div>
      {/* scrollbar */}
      <div className="px-4 mt-8 md:px-20 md:mt-[56px]">
        <div className="relative h-1 width-1440">
          <div className="h-full w-full bg-alter-black-10 rounded-lg" />
          <div
            className="absolute top-0 w-1/2 h-full bg-alter-black rounded-lg"
            style={{
              transform: `translateX(${scrollPercentage}%)`,
            }}
          />
        </div>
      </div>
    </section>
  );
};
