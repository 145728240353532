import { useRef, useState } from 'react';

export const useHorizontalScrollPercentage = () => {
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const containerRef = useRef<any>(null);

  const onContainerScroll = () => {
    if (!containerRef.current) return;

    const containerScrollWidth =
      containerRef.current.scrollWidth - containerRef.current.clientWidth;
    const containerScrollLeft = containerRef.current.scrollLeft;
    const scrolledPercentage = containerScrollWidth
      ? (containerScrollLeft / containerScrollWidth) * 100
      : 0;

    console.log(scrolledPercentage);
    setScrollPercentage(scrolledPercentage);
  };

  return {
    containerRef,
    scrollPercentage,
    onContainerScroll,
  };
};
