import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { useHorizontalScrollPercentage } from '@/hooks/useHorizontalScrollPercentage';
import type { ExpertT } from '@/utils/contentfulTypes';

interface AlterPerformanceCouncilProps {
  performanceCouncil: ExpertT[];
}

export const AlterPerformanceCouncil: React.FC<AlterPerformanceCouncilProps> = ({
  performanceCouncil,
}) => {
  const { containerRef, onContainerScroll, scrollPercentage } = useHorizontalScrollPercentage();

  return (
    <section className="md:bg-[#F6F6F1]">
      <div className="py-[58px] bg-white md:py-[90px] md:px-20 md:rounded-t-3xl">
        <div className="width-1440">
          <div className="flex flex-col gap-4 text-center">
            <h2 className="modernGothicTrial-light text-[28px] md:text-[48px] md:leading-snug">
              Backed by the <span className="text-graph-sage-50">Alter Performance Council</span>
            </h2>
            <p className="max-w-[76ch] mx-auto text-base text-alter-black-80 modernGothicTrial-light md:text-xl">
              Alter&apos;s Performance Council is a hand-selected group comprising the world&apos;s
              leading experts in the areas of sleep, fitness, human performance, and mental
              health/mindset.
              {/* <ContentfulTextContainer
              className="text-alter-bone-70 md:text-alter-bone-70 text-xl md:text-[23px]"
              markdown={performanceCouncilText}
            /> */}
            </p>
          </div>
          <div
            ref={containerRef}
            onScroll={onContainerScroll}
            className="flex gap-4 mt-10 overflow-auto md:flex-wrap md:justify-center md:mt-[3rem] no-scrollbar"
          >
            {performanceCouncil.map((item, index) => (
              <div
                key={item.name}
                className="max-w-[400px] [&_*]:modernGothicTrial-light first:ml-4 last:mr-4 md:basis-[23%]"
              >
                <Image
                  src={item.image.url}
                  alt={item.name}
                  width={400}
                  height={400}
                  className={clsx(
                    'min-w-[308px] md:min-w-[204px] aspect-square mx-auto object-cover rounded-[30px]'
                  )}
                />
                <h3 className="text-[20px] max-w-[399px] mt-4">{item.name}</h3>
                <div className="text-base text-alter-black-70 max-w-[399px] mt-2">
                  {item.positions}
                </div>
              </div>
            ))}
          </div>
          {
            <div className="md:hidden px-4 mt-8 md:px-20 md:mt-[56px]">
              <div className="relative h-1 width-1440">
                <div className="h-full w-full bg-alter-black-10 rounded-lg" />
                <div
                  className="absolute top-0 w-1/2 h-full bg-alter-black rounded-lg"
                  style={{
                    transform: `translateX(${scrollPercentage}%)`,
                  }}
                />
              </div>
            </div>
          }
          <Link
            href="/who-we-are"
            className="block mx-auto w-max button-outline-dark border mt-10 text-base md:mt-[56px]"
          >
            Learn more about us
          </Link>
        </div>
      </div>
    </section>
  );
};
