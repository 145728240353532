import Image from 'next/image';
import React from 'react';

interface FeaturedInProps {
  headline: string;
  images: {
    url: string;
  }[];
}

export const FeaturedIn: React.FC<FeaturedInProps> = ({ headline, images }) => {
  return (
    <section className="py-6 px-4 bg-white md:pt-[56px] md:pb-16">
      <div className="width-1440">
        <div className="w-[calc(100%-36px)] mx-auto rounded-3xl">
          <h3 className="text-alter-black-60 text-xs text-center uppercase">{headline}</h3>
          <div className="flex flex-wrap justify-center items-center gap-4 mt-4 md:justify-center md:gap-20">
            {images.map((img) => (
              <div key={img.url} className="h-10 w-1/3 md:w-[150px]">
                <Image
                  src={img.url}
                  alt="company logo"
                  width={150}
                  height={36}
                  className="object-contain h-10"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
