export * from './AlterPerformanceCouncil';
export * from './BackedByExperts';
export * from './EveryStep';
export * from './FeaturedIn';
export * from './HeroSection';
export * from './HomepageFaq';
export * from './HowAlterWorks';
export * from './JourneyWithAlter';
export * from './RealFitnessCoach';
export * from './StartToday';
