import { motion, useAnimate, useInView } from 'framer-motion';
import Image from 'next/image';
import React, { useEffect } from 'react';

import type { CardT } from '@/utils/contentfulTypes';

interface EveryStepProps {
  headline: string;
  items: CardT[];
}

export const EveryStep: React.FC<EveryStepProps> = ({ headline, items }) => {
  const [headerRef, animate] = useAnimate();
  const isInView = useInView(headerRef, {
    amount: 'all',
  });

  useEffect(() => {
    if (isInView) {
      animate(
        headerRef.current,
        { opacity: 1, translateY: 0 },
        {
          duration: 0.5,
          ease: 'easeOut',
        }
      );
    }
  }, [isInView]);

  return (
    <section className="bg-white">
      <div className="width-1440 w-full pb-16 mt-[22px] px-4  bg-white md:px-20 md:rounded-b-3xl">
        <motion.h2
          ref={headerRef}
          className="text-center modernGothicTrial-light text-[28px] md:text-[48px] md:leading-snug"
          initial={{ opacity: 0, translateY: 60 }}
        >
          {headline}
        </motion.h2>
        <div className="flex flex-col lg:flex-row gap-8 md:gap-4 mt-6 md:mt-20">
          {items.map((item) => (
            <div
              key={item.headline}
              className="flex flex-col flex-1 gap-4 border-l-2 border-l-graph-aqua-40 pt-2 pb-1 px-6 p text-sm"
            >
              <Image src={item.image.url} alt={item.headline} width={30} height={30} />
              <h3 className="text-xl">{item.headline}</h3>
              <p className=" text-base text-alter-black-80 modernGothicTrial-light">{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
