import NextImage from 'next/image';
import React, { useEffect, useState } from 'react';

import type { ImageT } from '@/utils/contentfulTypes';

interface AutoResizeImageProps {
  src: string;
  alt: string;
  logoHeight: number;
}

const AutoResizeImage: React.FC<AutoResizeImageProps> = ({ src, alt, logoHeight }) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      const aspectRatio = img.naturalWidth / img.naturalHeight;
      setWidth(aspectRatio * logoHeight);
    };
    img.src = src;
  }, [src, logoHeight]);

  if (width === 0) return null;

  return (
    <NextImage src={src} alt={alt} width={width} height={logoHeight} className="object-contain" />
  );
};

export default AutoResizeImage;

interface BackedByExpertsProps {
  className?: string;
  headline: string;
  items: ImageT[];
}

export const BackedByExperts: React.FC<BackedByExpertsProps> = ({
  className = 'py-[56px] px-4 bg-[#F6F6F1] md:p-20',
  headline,
  items,
}) => {
  const logoHeight = 45;

  return (
    <section className={className}>
      <div className="width-1440">
        <div className="w-full p-6 mx-auto rounded-3xl bg-white">
          <h3 className="text-earmark text-center">{headline}</h3>
          <div className="flex flex-wrap justify-center gap-4 md:gap-14 mt-4">
            {items.map((img) => (
              <div key={img.url} className="flex justify-center items-center">
                <AutoResizeImage src={img.url} alt="company logo" logoHeight={logoHeight} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
